import { Order, OrderStatusEnum } from "@/model/api/Order";
import { MainRoutesEnum } from "@/router/MainRoutesEnum";
import { orderService } from "@services/orders.service";
import { FilterMatchMode } from "primevue/api";
import { Options, Vue } from "vue-class-component";
import { OrdersRoutesEnum } from "../../router";

import {
  DynamicTable,
  MyAutocomplete,
} from "@components"
import { authStore } from "@/modules/auth/store";
import { defineComponent, h } from "vue";
import moment from "moment";
import { UserRoleEnum } from "@/model/enums/UserRoleEnum";
import { POSRoutesEnum } from "@/modules/pos/router";

@Options({
  components: {
    DynamicTable,
    MyAutocomplete
  }
})
export default class PosOrdersPage extends Vue {
  isTableLoading = false;
  totalRecordsLength = 0;
  reloadCount = 0;


  get service() {
    return orderService;
  }

  get cols() {
    let columns = [
      {
        field: 'order_code',
        filterField: 'order_code',
        header: this.$t('orders.table.order_code'),
      },
      {
        field: 'service_name',
        filterField: 'service_id',
        header: this.$t('orders.table.purchased_service'),

      },
      {
        field: 'provider_name',
        filterField: 'provider_id',
        header: this.$t('orders.table.provider'),
      },
      {
        field: 'service_date_at',
        filterField: null,
        header: this.$t('orders.table.purchase_date'),
      },
      {
        field: 'technician_fullname',
        filterField: 'technician_id',
        header: this.$t('orders.table.techician'),
      },
      {
        field: 'net_amount',
        filterField: null,
        header: this.$t('orders.table.taxable'),
        style: 'text-align: right; min-width: 10px; max-width: 100px;',
        headerColspan: 3
      },
      /*
      {
        field: 'gross_amount',
        filterField: null,
        header: this.$t('orders.table.amount'),
      },
      */
      {
        field: 'coupon_code',
        filterField: null,
        header: this.$t('orders.table.coupon_code'),
      },

      {
        field: 'status',
        filterField: null,
        header: this.$t('orders.table.status'),
      }
    ];
    if (authStore.getters.userRole === UserRoleEnum.SUPER_ADMIN) {
      const posCol = [{
        field: 'pos_name',
        filterField: 'pos_id',
        header: this.$t('orders.table.pos'),
      }];
      columns = posCol.concat(columns);
    }
    return columns;
  }

  get datatable(): any {
    return this.$refs.datatable;
  }

  filters = {
    order_code: {
      value: null,
      matchMode: FilterMatchMode.STARTS_WITH
    },
    name: {
      value: null,
      matchMode: FilterMatchMode.STARTS_WITH
    },
    pos_id: {
      value: null,
      matchMode: FilterMatchMode.EQUALS
    },
    service_id: {
      value: null,
      matchMode: FilterMatchMode.EQUALS
    },
    provider_id: {
      value: null,
      matchMode: FilterMatchMode.EQUALS
    },
    technician_id: {
      value: null,
      matchMode: FilterMatchMode.EQUALS
    },
    coupon_code: {
      value: null,
      matchMode: FilterMatchMode.CONTAINS
    },
    service_date_at: {
      value: null,
      matchMode: FilterMatchMode.EQUALS
    },
    net_amount: {
      value: null,
      matchMode: FilterMatchMode.EQUALS
    },
  };

  get statusOptions() {
    return [
      { 
        label: this.$t("order.status.ok001"), 
        value: OrderStatusEnum.OK001 
      },
      { 
        label: this.$t("order.status.okend"), 
        value: OrderStatusEnum.OKEND 
      },
      { 
        label: this.$t("order.status.kopos"), 
        value: OrderStatusEnum.KOPOS 
      },
      { 
        label: this.$t("order.status.koprv"), 
        value: OrderStatusEnum.KOPRV 
      },
    ]
  }

  get posId() {
    if (authStore.getters.userRole === UserRoleEnum.SUPER_ADMIN) {
      return 0;
    }

    return authStore.getters.me?.entity_id;
  }

  get title() {
    if (authStore.getters.userRole === UserRoleEnum.SUPER_ADMIN) {
      return this.$t("admin.orders.list.title");
    }
    return this.$t("orders.list.title");
  }
  
  get subtitle() {
    if (authStore.getters.userRole === UserRoleEnum.SUPER_ADMIN) {
      return this.$t("admin.orders.list.subtitle");
    }
    return this.$t("orders.list.subtitle");
  }

  customIndexReqTable(params) {
    const clone = JSON.parse(JSON.stringify(params));

    const dateFilter = clone.filters.service_date_at?.value;

    if (dateFilter) {
      const stringDate = moment(dateFilter).format('YYYY-MM-DD');
      clone.filters.service_date_at.value = stringDate;
    }

    const techFilter = clone.filters.technician_id?.value;
    if (techFilter) {
      clone.filters.technician_id.value = techFilter.id;
    }

    this.reloadCount++;
    return orderService.posOrders(this.posId, clone);
  }


  onTableLoadingChange(value: boolean) {
    this.isTableLoading = value;    
  }

  onTableTotalRecordsChange(value: number) {
    this.totalRecordsLength = value;
  }

  goDashboard() {
    this.$router.push({ name: MainRoutesEnum.DASHBOARD });
  }

  goToDetail(model: Order) {
    this.$router.push({
      name: OrdersRoutesEnum.ORDER_DETAILS,
      params: { orderId: model.id }
    });
  }

  applyFilters() {
    this.datatable.loadData();
  }

  private checkQueryParams() {
    const { technicianId, technicianName } = this.$route.query || {};
    this.filters.technician_id.value = technicianId  
      ? { id: +technicianId, name: technicianName } 
      : null;
  }

  created() {
    this.checkQueryParams();
  }
}