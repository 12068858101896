import { Order, OrderStatusEnum } from "@/model/api/Order";
import { MainRoutesEnum } from "@/router/MainRoutesEnum";
import { orderService } from "@services/orders.service";
import { FilterMatchMode } from "primevue/api";
import { Options, Vue } from "vue-class-component";
import { OrdersRoutesEnum } from "../../router";

import {
  DynamicTable,
  MyAutocomplete,
} from "@components"
import { authStore } from "@/modules/auth/store";
import { defineComponent, h } from "vue";
import moment from "moment";
import { UserRoleEnum } from "@/model/enums/UserRoleEnum";

@Options({
  components: {
    DynamicTable,
    MyAutocomplete
  }
})
export default class PosCustomerOrdersPage extends Vue {
  isTableLoading = false;
  totalRecordsLength = 0;
  reloadCount = 0;

  get service() {
    return orderService;
  }

  get cols() {
    let columns = [
      {
        field: 'order_code',
        filterField: 'order_code',
        header: this.$t('orders.table.order_code'),
      },
      {
        field: 'service_name',
        filterField: 'service_id',
        header: this.$t('orders.table.purchased_service'),
      },
      {
        field: 'service_date_at',
        filterField: null,
        header: this.$t('orders.table.purchase_date'),
      },
      {
        field: 'service_time_slot_from_at',
        filterField: null,
        header: this.$t('orders.table.service_time_slot_from_at'),
        style: 'min-width: 10px; max-width: 60px'
      },
      {
        field: 'service_time_slot_to_at',
        filterField: null,
        header: this.$t('orders.table.service_time_slot_to_at'),
        style: 'min-width: 10px; max-width: 60px'
      },
      {
        field: 'gross_amount',
        filterField: null,
        header: this.$t('orders.table.amount'),
        style: 'text-align: right; min-width: 10px; max-width: 100px;'
      },
      {
        field: 'coupon_code',
        filterField: 'coupon_code',
        header: this.$t('orders.table.coupon_code'),
        headerColspan: 2
      },
      {
        field: 'status',
        filterField: null,
        header: this.$t('orders.table.status'),
      },
    ];
    if (authStore.getters.userRole === UserRoleEnum.SUPER_ADMIN) {
      const posCol = [{
        field: 'pos_name',
        filterField: 'pos_id',
        header: this.$t('orders.table.pos'),
      }];
      columns = posCol.concat(columns);
    }
    return columns;
  }

  get datatable(): any {
    return this.$refs.datatable;
  }

  filters = {
    order_code: {
      value: null,
      matchMode: FilterMatchMode.STARTS_WITH
    },
    name: {
      value: null,
      matchMode: FilterMatchMode.STARTS_WITH
    },
    pos_id: {
      value: null,
      matchMode: FilterMatchMode.EQUALS
    },
    service_id: {
      value: null,
      matchMode: FilterMatchMode.EQUALS
    },
    coupon_code: {
      value: null,
      matchMode: FilterMatchMode.CONTAINS
    },
    service_date_at: {
      value: null,
      matchMode: FilterMatchMode.EQUALS
    },
    status: {
      value: null,
      matchMode: FilterMatchMode.EQUALS
    },
  };

  get statusOptions() {
    return [
      { 
        label: this.$t("order.status.ok001"), 
        value: OrderStatusEnum.OK001 
      },
      { 
        label: this.$t("order.status.okend"), 
        value: OrderStatusEnum.OKEND 
      },
      { 
        label: this.$t("order.status.kopos"), 
        value: OrderStatusEnum.KOPOS 
      },
      { 
        label: this.$t("order.status.koprv"), 
        value: OrderStatusEnum.KOPRV 
      },
    ]
  }

  get posId() {
    if (authStore.getters.userRole === UserRoleEnum.SUPER_ADMIN) {
      return 0;
    }

    return authStore.getters.me?.entity_id;
  }
  
  customIndexReqTable(params) {
    const clone = JSON.parse(JSON.stringify(params));

    const dateFilter = clone.filters.service_date_at?.value;

    if (dateFilter) {
      const stringDate = moment(dateFilter).format('YYYY-MM-DD');
      clone.filters.service_date_at.value = stringDate;
    }

    this.reloadCount++;
    return orderService.posCustomerOrders(this.posId, clone);
  }

  onTableLoadingChange(value: boolean) {
    this.isTableLoading = value;    
  }

  onTableTotalRecordsChange(value: number) {
    this.totalRecordsLength = value;
  }

  goDashboard() {
    this.$router.push({ name: MainRoutesEnum.DASHBOARD });
  }

  goToDetail(model: Order) {
    this.$router.push({
      name: OrdersRoutesEnum.POS_CUSTOMER_ORDER_DETAILS,
      params: { orderId: model.id }
    });
  }

  applyFilters() {
    this.datatable.loadData();
  }

  private checkQueryParams() {
  }

  created() {
    this.checkQueryParams();
  }
}