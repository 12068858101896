import { Order, OrderStatusEnum } from "@/model/api/Order";
import { CouponsRoutesEnum } from "@/modules/coupons/router";
import { POSRoutesEnum } from "@/modules/pos/router";
import { orderService } from "@services/orders.service";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Options({})
export default class PosCustomerOrderPage extends Vue {
  @Prop() readonly orderId!: String;

  order: Order = null; 

  get dateFormat() {
    return "DD/MM/YYYY";
  }

  get isEnd() {
    return this.order?.status === OrderStatusEnum.OKEND;
  }

  get isKo() {
    return this.isKOPOS || this.isKOPRV;
  }

  get isKOPOS() {
    return this.order?.status === OrderStatusEnum.KOPOS;
  }

  get isKOPRV() {
    return this.order?.status === OrderStatusEnum.KOPRV;
  }

  private loadOrder() {
    return this.$waitFor(async () => {
      this.order = await orderService.getById(+this.orderId);
    });
  }

  created() {
    if (!this.orderId) {
      this.$router.replace({ name: POSRoutesEnum.POS_CUSTOMER_ORDERS});
      return;
    }
    this.loadOrder();
  }

  goCoupon() {
    this.$router.push({ name: CouponsRoutesEnum.COUPON_DETAILS, params: { couponId: this.order?.coupon.id } });
  }
}